<script setup lang="ts">
import { ref } from "vue";

type Props = {
  label?: string;
  name: string;
  icon?: string;
};

withDefaults(defineProps<Props>(), {
  icon: "",
  label: "",
});

const trigger = ref<HTMLButtonElement | null>();
const popover = ref<HTMLDivElement | null>();
function onToggle(e: ToggleEvent) {
  if (e.newState === "open") {
    if (trigger.value && popover.value) {
      const { right: triggerRight, bottom: triggerBottom } = trigger.value.getBoundingClientRect();
      const { width: popoverWidth } = popover.value.getBoundingClientRect();
      popover.value.style.top = triggerBottom + window.scrollY + 4 + "px";
      popover.value.style.left = `${triggerRight - popoverWidth}px`;
    }
  }
}
</script>

<template>
  <div>
    <button
      v-if="!icon"
      ref="trigger"
      type="button"
      class="h-6 min-h-0 w-8 min-w-8 cursor-default rounded px-1 text-sm text-blue ring-1 ring-blue-500 active:bg-blue-500 active:text-white"
      :popovertarget="`${name}-popover`"
    >
      {{ label }}
    </button>
    <button v-else ref="trigger" type="button" :popovertarget="`${name}-popover`">
      <i :class="{ 'fa-solid fa-asterisk fa-xs text-blue': icon === 'asterisk' }" />
    </button>
    <div
      :id="`${name}-popover`"
      ref="popover"
      class="absolute inset-[unset] rounded-md border bg-white shadow-lg"
      popover
      @toggle="onToggle"
    >
      <slot />
    </div>
  </div>
</template>
