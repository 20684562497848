import { BAPI_COMMANDS } from "@/bapi-client/types/commands";
import { fetchHandler } from "@/bapi-client/fetch";
import { Result } from "@/bapi-client/types";
import {
  InvoicingPayee,
  InvoicingPayeeBody,
  InvoicingCompany,
  InvoicingCompanyBody,
  ExternalInvoiceForm,
  ExternalInvoice,
  GetAllInvoicesResponse,
  GetAllInvoicesParams,
} from "@/bapi-client/types/external-invoicing";
import { invoicingPayeeFactory, invoicingCompanyFactory } from "@/bapi-client/factories/external-invoicing";
import { useFetch } from "@/composables/fetch";
import useNotificationStore from "@/stores/notifications";
import { uploadS3File } from "@/bapi-client/handlers";

type CreateCompanyParams = Pick<InvoicingCompany, "name" | "settings">;

export async function getInvoicingPayee(customerId: string): Promise<Result<InvoicingPayee>> {
  const response = await fetchHandler(
    BAPI_COMMANDS.EXTERNAL_INVOICING_GET_PAYEE,
    "GET",
    `/invoicing/${customerId}/company`,
  );
  if (!response.success) return response;

  const parsed = await response.data.json();
  if (!parsed.success) return parsed;

  return {
    success: true,
    data: invoicingPayeeFactory(parsed.data),
  };
}

export async function updateInvoicingPayee(
  customerId: string,
  payeeProfileId: string,
  body: InvoicingPayeeBody,
): Promise<Result<InvoicingPayee>> {
  const response = await fetchHandler(
    BAPI_COMMANDS.EXTERNAL_INVOICING_UPDATE_PAYEE,
    "PATCH",
    `/invoicing/${customerId}/company/${payeeProfileId}`,
    { body },
  );
  if (!response.success) return response;
  const parsed = await response.data.json();
  if (!parsed.success) return parsed;

  return {
    success: true,
    data: invoicingPayeeFactory(parsed.data),
  };
}

export async function getInvoicingCompany(customerId: string, payerId: string): Promise<Result<InvoicingCompany>> {
  const response = await fetchHandler(
    BAPI_COMMANDS.EXTERNAL_INVOICING_GET_COMPANY,
    "GET",
    `/invoicing/${customerId}/payer_company/${payerId}`,
  );
  if (!response.success) return response;

  const parsed = await response.data.json();
  if (!parsed.success) return parsed;

  return {
    success: true,
    data: invoicingCompanyFactory(parsed.data),
  };
}

export async function createInvoicingCompany(
  customerId: string,
  body: CreateCompanyParams,
): Promise<Result<InvoicingCompany>> {
  const response = await fetchHandler(
    BAPI_COMMANDS.EXTERNAL_INVOICING_CREATE_COMPANY,
    "POST",
    `/invoicing/${customerId}/payer_company/init`,
    { body },
  );
  if (!response.success) return response;
  const parsed = await response.data.json();
  if (!parsed.success) return parsed;

  return {
    success: true,
    data: invoicingCompanyFactory(parsed.data),
  };
}

export async function updateInvoicingCompany(
  customerId: string,
  payerId: string,
  body: InvoicingCompanyBody,
): Promise<Result<InvoicingCompany>> {
  const response = await fetchHandler(
    BAPI_COMMANDS.EXTERNAL_INVOICING_UPDATE_COMPANY,
    "PATCH",
    `/invoicing/${customerId}/payer_company/${payerId}`,
    { body },
  );
  if (!response.success) return response;
  const parsed = await response.data.json();
  if (!parsed.success) return parsed;

  return {
    success: true,
    data: invoicingCompanyFactory(parsed.data),
  };
}

export async function attachLogo(logo: File, profileName: string) {
  const request = useFetch();
  const notifier = useNotificationStore();

  const signed = await request.get("/company/logo/upload");
  const signedResponse = await signed.json();
  if (signedResponse.success) {
    const uploadResponse = await uploadS3File(logo, signedResponse.upload_url);

    if (!uploadResponse.success) {
      console.error("Failed to upload logo file", uploadResponse.error);
      notifier.setToast("info", "Could not upload logo");
      return;
    }

    return {
      attachment_id: signedResponse.attachment_id,
      filename: logo.name,
      filesize: logo.size,
      alt_text: `${profileName} logo`,
      mime_type: logo.type,
    };
  }
}

export async function getAllExternalInvoices(
  customerId: string,
  payeeId: string,
  params: GetAllInvoicesParams,
): Promise<Result<GetAllInvoicesResponse>> {
  // take params and turn them into URL query params
  const query = new URLSearchParams(params).toString();

  const response = await fetchHandler(
    BAPI_COMMANDS.EXTERNAL_INVOICING_GET_ALL_INVOICES,
    "GET",
    `/invoicing/${customerId}/payee/${payeeId}/invoices/list?${query}`,
  );
  if (!response.success) return response;

  const parsed = await response.data.json();
  if (!parsed.success) return parsed;

  return parsed;
}
export async function createExternalInvoice(
  customerId: string,
  payeeId: string,
  form: ExternalInvoiceForm,
): Promise<Result<ExternalInvoice>> {
  const response = await fetchHandler(
    BAPI_COMMANDS.EXTERNAL_INVOICING_CREATE_INVOICE,
    "POST",
    `/invoicing/${customerId}/payee/${payeeId}/invoices`,
    {
      body: form,
    },
  );
  if (!response.success) return response;
  const json = await response.data.json();
  if (!json.success) return json;
  return {
    success: true,
    data: json.data,
  };
}

export async function updateExternalInvoice(
  customerId: string,
  payeeId: string,
  invoiceId: string,
  form: ExternalInvoiceForm,
): Promise<Result<ExternalInvoice>> {
  const response = await fetchHandler(
    BAPI_COMMANDS.EXTERNAL_INVOICING_UPDATE_INVOICE,
    "PATCH",
    `/invoicing/${customerId}/payee/${payeeId}/invoices/${invoiceId}/update`,
    {
      body: form,
    },
  );
  if (!response.success) return response;
  const json = await response.data.json();
  if (!json.success) return json;
  return {
    success: json.success,
    data: json.data,
  };
}

export async function getExternalInvoice(
  customerId: string,
  payeeId: string,
  invoiceId: string,
): Promise<Result<ExternalInvoice>> {
  const response = await fetchHandler(
    BAPI_COMMANDS.EXTERNAL_INVOICING_GET_INVOICE,
    "GET",
    `/invoicing/${customerId}/payee/${payeeId}/invoices/${invoiceId}`,
  );
  if (!response.success) return response;
  const json = await response.data.json();
  if (!json.success) return json;
  return {
    success: json.success,
    data: json.data,
  };
}

export async function deleteExternalInvoice(
  customerId: string,
  payeeId: string,
  invoiceId: string,
): Promise<{ success: boolean }> {
  const response = await fetchHandler(
    BAPI_COMMANDS.EXTERNAL_INVOICING_DELETE_INVOICE,
    "DELETE",
    `/invoicing/${customerId}/payee/${payeeId}/invoices/${invoiceId}`,
  );
  if (!response.success) return response;
  const json = await response.data.json();
  if (!json.success) return json;
  return { success: json.success };
}
